@font-face {
  font-family: 'AvantGarde';
  src: url('../fonts/ITCAvantGardeStdBk.eot'); /* IE9 Compat Modes */
  src: url('../fonts/ITCAvantGardeStdBk.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ITCAvantGardeStdBk.woff') format('woff'), /* Modern Browsers */
       url('../fonts/ITCAvantGardeStdBk.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/ITCAvantGardeStdBk.svg#7510147900d23fa3ad697e74bf146ea2') format('svg'); /* Legacy iOS */

  font-weight:  400;
  font-style:   normal;
}

@font-face {
  font-family: 'AvantGarde';
  src: url('../fonts/ITCAvantGardeStdDemi.eot'); /* IE9 Compat Modes */
  src: url('../fonts/ITCAvantGardeStdDemi.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/ITCAvantGardeStdDemi.woff') format('woff'), /* Modern Browsers */
       url('../fonts/ITCAvantGardeStdDemi.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/ITCAvantGardeStdDemi.svg#bdc473fae2f64b1c45b8886bcff81bae') format('svg'); /* Legacy iOS */

  font-weight:  600;
  font-style:   normal;
}

@font-face {
  font-family: 'KabelBlack';
  src: url('../fonts/KabelLTStdBlack.eot'); /* IE9 Compat Modes */
  src: url('../fonts/KabelLTStdBlack.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/KabelLTStdBlack.woff') format('woff'), /* Modern Browsers */
       url('../fonts/KabelLTStdBlack.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/KabelLTStdBlack.svg#bdc473faa2f64b1c45b8886bcff81bae') format('svg'); /* Legacy iOS */

  font-weight:  600;
  font-style:   normal;
}

.nav-justified>li>a{
	color:#fff;
	padding:10px 10px;
}

.nav-justified>li>a:hover{
background: lighten($brand-primary, 10%);
}

.main{
	padding:0!important;
}

.cont{

}

.hdbg{
	position:relative;
}

.hdov{
	position:absolute;
	width:100%;
	height:100%;
	top: 0;
	left: 0;
	z-index:2;
	}

	.logoimage{
			top: 10%;

	}
	.swiper-pagination{
		z-index:5;
	}

.hdov .container{
	height:100%;
}


.hdov.bg img{
	height:101%;
	width:100%;
}

.rightjust{
height:100%;
}

.tx{
position:absolute;
text-align:right;
bottom:20px;
color:#fff;
width:40%;
left:60%;
text-shadow: 3px 3px 3px #000 ;
text-shadow: 3px 3px 3px rgba(0,0,0,0.5) ;
font-family:AvantGarde;
font-weight:600;
  display: -webkit-flex; /* Safari */
  display: flex;
  align-items: center;
    -webkit-justify-content: center; /* Safari */
  justify-content: center;
    -webkit-flex-direction: row-reverse; /* Safari */
  flex-direction:         row-reverse;


@media(min-width:768px){

}
@media(min-width:992px){

}
@media(min-width:1200px){
}
}


span.slibig{
	font-size:130%;
	line-height:100%;
}
.tx2{
position:absolute;
text-align:left;
bottom:10px;
color:#fff;
    text-shadow:
    -2px -2px 0 #be1e2d,
    2px -2px 0 #be1e2d,
    -2px 2px 0 #be1e2d,
    2px 2px 0 #be1e2d,
    5px 5px 5px #000
    ;  

text-shadow: 
    -2px -2px 0 #be1e2d,
    2px -2px 0 #be1e2d,
    -2px 2px 0 #be1e2d,
    2px 2px 0 #be1e2d,
    5px 5px 5px rgba(0,0,0,0.5) 
    ;
font-family:AvantGarde;
font-weight:600;
  display: -webkit-flex; /* Safari */
  display: flex;
  align-items: center;
    -webkit-justify-content: center; /* Safari */
  justify-content: center;
    -webkit-flex-direction: row-reverse; /* Safari */
  flex-direction:         row-reverse;


@media(min-width:768px){

}
@media(min-width:992px){

}
@media(min-width:1200px){
}
}


.txt{
// width:20%;
// float:right;
}


.imagin{
float:right;
width:100%;
} 

.imagin img{
// width:142px;
}

span.headtxt{
padding-bottom:0;
@media(max-width: $screen-xs-max){
font-size:150%;
line-height: 100%;
}
@media(min-width: $screen-sm-min){
font-size:300%;
line-height: 100%;
}
@media(min-width: $screen-md-min){
font-size:390%;
line-height: 100%;
}
@media(min-width: $screen-lg-min){
font-size:470%;
line-height: 100%;
}
}



span.headtxt2{
@media(max-width: $screen-xs-max){
font-size:100%;
line-height: 90%;
}
@media(min-width: $screen-sm-min){
font-size:140%;
line-height: 130%;
}
@media(min-width: $screen-md-min){
font-size:180%;
line-height: 130%;
}
@media(min-width: $screen-lg-min){
font-size:230%;
line-height: 130%;
}
}




.con.s1{
@media(max-width: $screen-xs-max){
}
@media(min-width: $screen-sm-min){
}
@media(min-width: $screen-md-min){
}
@media(min-width: $screen-lg-min){
margin-left:0%;
      width:100%;
}
}

.con2.s1{
@media(max-width: $screen-xs-max){
}
@media(min-width: $screen-sm-min){
}
@media(min-width: $screen-md-min){
}
@media(min-width: $screen-lg-min){
margin-left:0%;
      width:100%;
}
}


.con.s2{
@media(max-width: $screen-xs-max){
}
@media(min-width: $screen-sm-min){
}
@media(min-width: $screen-md-min){
}
@media(min-width: $screen-lg-min){
margin-left:0%;
      width:100%;
}
}

.con2.s2{
@media(max-width: $screen-xs-max){
}
@media(min-width: $screen-sm-min){
}
@media(min-width: $screen-md-min){
}
@media(min-width: $screen-lg-min){
margin-left:0%;
      width:100%;
}
}


.con.s3{
@media(max-width: $screen-xs-max){
}
@media(min-width: $screen-sm-min){
}
@media(min-width: $screen-md-min){
}
@media(min-width: $screen-lg-min){
margin-left:0%;
      width:100%;
}
}

.con2.s3{
@media(max-width: $screen-xs-max){
}
@media(min-width: $screen-sm-min){
}
@media(min-width: $screen-md-min){
}
@media(min-width: $screen-lg-min){
margin-left:0%;
      width:100%;
}
}


	h2.ti{
		text-transform:uppercase;
				margin-bottom:0;
font-weight:600;


	}

		h3.ti{
		color:$brand-primary;
		margin-top:0;
	}



#about, #buy{
	background:#fff;
	min-height:100px;
	padding:50px 0;
	margin-top:00px;
	font-size:130%;
	ul.list-group{
		li.list-group-item{
			min-height:160px;
				border:0;
						display:table;

				.cen{
				display:table-cell;
				vertical-align:middle;
					img{
					margin-left:auto;margin-right:auto;
					max-width:100%;

					}
				}
			}
		}
	}

#products{
	background:$brand-primary;
	min-height:100px;
	color:#fff;
	padding:50px 0;
	.product{
		text-align:center;
		p{
		padding:0;
		margin:0;
		}
		h3{
			text-transform:uppercase;
			font-family:KabelBlack;
					padding:0 30px;
					margin-bottom:0;
		}
		a{
			color:white;
		}
	}
}

#contact{
	background:#404041;
	min-height:100px;
	color:#fff;
		padding:20px 0 0 0;
.con{
			padding:20px 0 0 0;
}
}

 #footer{
		background:#404041;
	color:#fff;
			padding:20px 0;
			font-size:90%;

a {	color:#fff;
}
 }

.nopad{padding:0;}

.soc{
	font-size: 250%;
}

.thedogs{
}


main .container{
	padding:60px 0;
}

p.subs{
	font-size:110%;
	font-style:italic;
	padding:20px 0;
}

.buut{
		margin-bottom:20px;

	@media(max-width: $screen-xs-max){
}
@media(min-width: $screen-sm-min){
}
@media(min-width: $screen-md-min){
	margin-top:10px;
}
@media(min-width: $screen-lg-min){
	margin-top:40px;
}
}